<script setup>
import {computed} from 'vue';
import {trans} from "laravel-vue-i18n";

const props = defineProps({
    modelValue: Object | Number,
    disabled: Boolean,
    items: Array,
    titleField: String | undefined,
    valueField: String | undefined,
    emptyTitle: String | undefined,
    hasError: Boolean | undefined,
    defaultItem: {
        type: Boolean,
        default: true
    }
});


const selectItems = computed(() => {
    return props.defaultItem ? [{id: null, [props.titleField ?? 'title']: props.emptyTitle ?? trans('Select')}, ...props.items] : props.items
});

const emit = defineEmits(['update:modelValue']);

const onSelect = (e) => {
    emit('update:modelValue', typeof selectItems.value[e.target.value].id !== 'undefined' ? selectItems.value[e.target.value].id : selectItems.value[e.target.value])
}
const value = computed(() => {
    return selectItems.value.findIndex(item => {
        if (props.modelValue[props.valueField] === item[props.valueField]) {
            return props.modelValue[props.valueField] === item[props.valueField]
        } else if (typeof item.id !== 'undefined') {
            return item.id === props.modelValue
        } else {
            return item[props.titleField] === props.modelValue[props.titleField]
        }
    })
})
</script>

<template>

    <select
        :disabled="disabled"
        class="border-0 focus:border-0 w-fit p-0 items-center text-xl leading-120 font-bold focus:outline-none transition ease-in-out whitespace-nowrap"
        @input="onSelect"
        :value="value"
    >
        <option v-for="(item, index) in selectItems" :value="index">
            {{
                titleField ? item[titleField] : item.title
            }}
        </option>
    </select>

</template>
